// Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar({ aboutRef, servicesRef, contactRef }) {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollPosition = window.scrollY;
  
      if (servicesRef.current && contactRef.current) {
        if (
          scrollPosition < servicesRef.current.offsetTop - 300 &&
          activeSection !== 'about'
        ) {
          setActiveSection('about');
        } else if (
          scrollPosition >= servicesRef.current.offsetTop - 300 &&
          scrollPosition < contactRef.current.offsetTop - 300 &&
          activeSection !== 'services'
        ) {
          setActiveSection('services');
        } else if (
          scrollPosition >= contactRef.current.offsetTop - 300 &&
          activeSection !== 'contact'
        ) {
          setActiveSection('contact');
        }
      }
    };
  
    window.addEventListener('scroll', scrollHandler);
  
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [activeSection, servicesRef, contactRef]);
  

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="fixed top-0 left-0 z-40 w-full border-b border-gray-200 bg-white py-2.5 px-6 sm:px-4">
      <div className="container mx-auto flex max-w-6xl flex-wrap items-center justify-between">
        <a href="#" className="flex items-center">
          
          <span className="self-center whitespace-nowrap text-xl font-semibold">
          Medisync
          </span>
        </a>
        <div className="mt-2 sm:mt-0 sm:flex md:order-2">
<div className="mt-4 sm:mt-0 sm:flex md:order-2">

<Link to="/login">
  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4">
    Login
  </button>
</Link>
<Link to="/signup">
  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
    Register
  </button>
</Link>
</div>

        </div>
        <div
          className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
          id="navbar-sticky"
        >
          <ul className="mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:text-sm md:font-medium">

            <li>
              <a
                href="#services"
                className={`block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700 ${
                  activeSection === 'services' ? 'bg-blue-500 text-white' : ''
                }`}
                onClick={() => scrollToRef(servicesRef)}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#features"
                className={`block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700 ${
                  activeSection === 'services' ? 'bg-blue-500 text-white' : ''
                }`}
                onClick={() => scrollToRef(servicesRef)}
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className={`block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700 ${
                  activeSection === 'contact' ? 'bg-blue-500 text-white' : ''
                }`}
                onClick={() => scrollToRef(contactRef)}
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="#about"
                className={`block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700 ${
                  activeSection === 'about' ? 'bg-blue-500 text-white' : ''
                }`}
                onClick={() => scrollToRef(aboutRef)}
              >
                About
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
