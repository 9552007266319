import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faChartBar, faFilter } from '@fortawesome/free-solid-svg-icons';

const servicesData = [
  {
    title: "Ranklist by Number of Problems Solved",
    description: "Track student problem-solving proficiency effortlessly. Gain insights into dedication and competence on competitive platforms.",
    svg: <FontAwesomeIcon icon={faTrophy} />
  },
  {
    title: "Ranklist by Contest Rating",
    description: "Stay competitive. Monitor ratings, attendance, and global ranking to strategize effectively.",
    svg: <FontAwesomeIcon icon={faChartBar} />
  },
  {
    title: "Ranklist by Filtered Contest Title",
    description: "Customize analysis. Focus evaluations with detailed participant insights for informed decisions.",
    svg: <FontAwesomeIcon icon={faFilter} />
  }
];


export default function Features() {
  return (
    <>
      {/* Component */}
      <link
        rel="stylesheet"
        href="https://cdn.tailgrids.com/tailgrids-fallback.css"
      />
      {/* Services Section Start */}
      <section id='services' className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px]">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div className="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
                <span className="font-semibold text-lg text-primary mb-2 block">
                  Our Services
                </span>
                <h2 className="font-bold text-3xl sm:text-4xl md:text-[40px] text-dark mb-4">
                  What We Offer
                </h2>
                <p className="text-base text-body-color">
                  There are many variations of passages of Lorem Ipsum
                  available but the majority have suffered alteration in some
                  form.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            {servicesData.map((feature, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-4">
                <div className="p-10 md:px-7 xl:px-10 rounded-[20px] bg-white shadow-md hover:shadow-lg mb-8">
                  <div className="w-[70px] h-[70px] flex items-center justify-center bg-primary rounded-2xl mb-8 text-white">
                    {feature.svg}
                  </div>
                  <h4 className="font-semibold text-xl text-dark mb-3">
                    {feature.title}
                  </h4>
                  <p className="text-body-color">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
