import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';

const Dashboard = () => {
  const [codeforcesData, setCodeforcesData] = useState(null);
  const [codechefData, setCodechefData] = useState(null);
  const [githubData, setGithubData] = useState(null);
  const [topRepositories, setTopRepositories] = useState([]);
  const [totalStars, setTotalStars] = useState(0);
  const [totalCommits, setTotalCommits] = useState(0);
  const [totalContributions, setTotalContributions] = useState(0);
  const [mostUsedLanguages, setMostUsedLanguages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [contestData, setContestData] = useState(null);
  const [solvedData, setSolvedData] = useState(null);
  const id = "mahsook";
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Codeforces data
        const codeforcesResponse = await fetch('https://codeforce-stutrack.vercel.app/mmohanraj');
        const codeforcesData = await codeforcesResponse.json();
        setCodeforcesData(codeforcesData);

        // Fetch CodeChef data
        const codechefResponse = await fetch('https://codechef-stu-track.vercel.app/sece_mec091');
        const codechefData = await codechefResponse.json();
        setCodechefData(codechefData);

        // Fetch GitHub data
        const githubResponse = await fetch('https://api.github.com/users/mahsook3');
        const githubData = await githubResponse.json();
        setGithubData(githubData);

        // Fetch top repositories from GitHub
        const reposResponse = await fetch(githubData.repos_url);
        const reposData = await reposResponse.json();
        const sortedRepos = reposData.sort((a, b) => b.stargazers_count - a.stargazers_count).slice(0, 5);
        setTopRepositories(sortedRepos);
        const totalStars = sortedRepos.reduce((acc, repo) => acc + repo.stargazers_count, 0);
        setTotalStars(totalStars);

        // Fetch user events from GitHub
        const eventsResponse = await fetch(githubData.events_url);
        const eventsData = await eventsResponse.json();
        const commits = eventsData.filter(event => event.type === 'PushEvent').length;
        setTotalCommits(commits);
        const contributions = eventsData.length;
        setTotalContributions(contributions);
        const languages = eventsData.filter(event => event.type === 'PushEvent').flatMap(event => event.payload.commits.map(commit => commit.language));
        const languageCount = languages.reduce((acc, lang) => {
          acc[lang] = (acc[lang] || 0) + 1;
          return acc;
        }, {});
        const sortedLanguages = Object.entries(languageCount).sort((a, b) => b[1] - a[1]).slice(0, 5);
        setMostUsedLanguages(sortedLanguages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

useEffect(() => {
  const renderChart = (contestsData) => {
    if (chartRef.current !== null) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Codeforces', 'CodeChef', 'LeetCode'],
          datasets: [
            {
              label: 'Contests Attended',
              data: [contestsData.codeforces, contestsData.codechef, contestsData.leetcode],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)'
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            title: {
              display: true,
              text: 'Contests Attended'
            },
            responsive: true,


          }
        }
      });
    }
  };

  // Usage:
  const contestsData = {
    codeforces: codeforcesData?.ratingHistory.length || 0,
    codechef: codechefData?.totalContestAttended || 0,
    leetcode: userData?.contestAttend || 0
  };
  renderChart(contestsData);
}, [codeforcesData, codechefData, userData]);

  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch LeetCode data
        const leetCodeResponse = await fetch(`https://leetcode-all-stutrack.vercel.app/${id}/contest`);
        const leetCodeData = await leetCodeResponse.json();
        setUserData(leetCodeData);
  
        // Fetch contest data
        const contestResponse = await fetch(`https://leetcode-all-stutrack.vercel.app/${id}/contest`);
        const contestData = await contestResponse.json();
        setContestData(contestData);
  
        // Fetch solved data
        const solvedResponse = await fetch(`https://leetcode-all-stutrack.vercel.app/${id}/solved`);
        const solvedData = await solvedResponse.json();
        setSolvedData(solvedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchUserData();
  }, []);
  
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Render chart */}
      <div className="mt-8">
      <canvas ref={chartRef} className="chartCanvas"></canvas>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Render LeetCodeDashboard */}
        {userData && (
          <div className="bg-white rounded-lg shadow-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <img src="https://assets.leetcode.com/static_assets/public/icons/favicon-96x96.png" alt="LeetCode Logo" className="w-8 h-8 mr-2" />
              LeetCode
            </h2>
            <div className="text-gray-600">
              <p>Contest Rating: {userData.contestRating}</p>
              <p>Contests Attended: {userData.contestAttend}</p>
              <p>Total Problems Solved: {solvedData ? solvedData.solvedProblem : 'Loading...'}</p>
              <p>Easy Problems Solved: {solvedData ? solvedData.easySolved : 'Loading...'}</p>
              <p>Medium Problems Solved: {solvedData ? solvedData.mediumSolved : 'Loading...'}</p>
              <p>Hard Problems Solved: {solvedData ? solvedData.hardSolved : 'Loading...'}</p>
            </div>
          </div>
        )}
        
        {/* Render Codeforces data */}
        {codeforcesData && (
          <div className="bg-white rounded-lg shadow-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <img src="https://cdn.iconscout.com/icon/free/png-256/free-code-forces-3628695-3029920.png" alt="Codeforces Logo" className="w-8 h-8 mr-2" />
              Codeforces
            </h2>
            <div className="text-gray-600">
              <p>Rating: {codeforcesData.rating}</p>
              <p>Rank: {codeforcesData.rank}</p>
              <p>Solved Problems: {codeforcesData.solvedProblems}</p>
              <p>Contests Attended: {codeforcesData.ratingHistory.length}</p>
            </div>
          </div>
        )}

        {/* Render CodeChef data */}
        {codechefData && (
          <div className="bg-white rounded-lg shadow-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <img src="https://avatars.githubusercontent.com/u/11960354?v=4" alt="CodeChef Logo" className="w-8 h-8 mr-2" />
              CodeChef
            </h2>
            <div className="text-gray-600">
              <p>Current Rating: {codechefData.currentRating}</p>
              <p>Highest Rating: {codechefData.highestRating}</p>
              <p>Stars: {codechefData.stars}</p>
              <p>Global Rank: {codechefData.globalRank}</p>
              <p>Country Rank: {codechefData.countryRank}</p>
              <p>Contests Attended: {codechefData.totalContestAttended}</p>
            </div>
          </div>
        )}

        {/* Render GitHub data */}
        {githubData && (
          <div className="bg-white rounded-lg shadow-lg p-4">
            <div className="flex items-center mb-4">
              <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" alt="GitHub Logo" className="w-8 h-8 mr-2" />
              <h2 className="text-lg font-semibold">GitHub</h2>
            </div>
            <div className="text-gray-600">
              <p>Public Repositories: {githubData.public_repos}</p>
              <p>Followers: {githubData.followers}</p>
              <p>Following: {githubData.following}</p>
              <p>Bio: {githubData.bio}</p>
              <p>Total Stars Earned: {totalStars}</p>
              <ul>
                {mostUsedLanguages.map(([language, count]) => (
                  <li key={language}>{language}: {count}</li>
                ))}
              </ul>
            </div>
            <div>
              <img src={githubData.avatar_url} alt="Avatar" className="w-20 h-20 rounded-full" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
