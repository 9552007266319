// Home.js
import React, { useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

import Hero from "./Hero";
import Features from "./Features";
import Services from "./Services";
import About from "./About";
import Contact from "./Contact";

export default function Home() {
  const aboutRef = useRef(null); // Define ref for About section
  const servicesRef = useRef(null); // Define ref for Services section
  const contactRef = useRef(null); // Define ref for Contact section

  return (
    <>
      <Navbar 
        aboutRef={aboutRef} 
        servicesRef={servicesRef} 
        contactRef={contactRef}
      />
      <Hero />
      <Services /> 
      <Features />
      <Contact  /> 
      <About /> 
      <Footer />
    </>
  );
}
