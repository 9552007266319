import React from 'react';

const featuresData = [
  {
    number: "01.",
    title: "Automated Processes",
    description: "Reduce manual workloads with automated tracking and analysis. Save time and resources while gaining valuable insights into student performance."
  },
  {
    number: "02.",
    title: "Collaborative Environment",
    description: "Foster collaboration among students by providing a platform for sharing insights, strategies, and best practices. Encourage teamwork and mutual support in competitive programming endeavors."
  },
  {
    number: "03.",
    title: "Personalized Dashboard",
    description: "Access personalized dashboards tailored to individual users' preferences and needs. Stay informed with real-time updates and customizable features for a seamless user experience."
  },
  {
    number: "04.",
    title: "Data Visualization",
    description: "Visualize student performance data with intuitive charts and graphs. Easily interpret trends, identify patterns, and make informed decisions to drive academic success."
  },
  {
    number: "05.",
    title: "User-Friendly Interface",
    description: "Navigate seamlessly through StuTrack's user-friendly interface. Access features effortlessly and maximize productivity with intuitive design and layout."
  },
  {
    number: "06.",
    title: "Secure Data Handling",
    description: "Ensure data security and confidentiality with robust encryption and secure data handling practices. Rest assured that sensitive information is protected at all times."
  },
  {
    number: "07.",
    title: "Continuous Updates and Support",
    description: "Benefit from continuous updates and dedicated support to ensure optimal performance and user satisfaction. Stay up-to-date with the latest features and enhancements for an enhanced user experience."
  }
];

const Features = () => {
  return (
    <div className="flex justify-center items-center h-full" id='features' >
      <div className="p-4 text-gray-600">
        <h1 className="mb-8 text-center text-3xl font-bold text-indigo-900">
        StuTrack Features
        </h1>
        <ul className="grid place-content-center sm:grid-cols-2 gap-8">
          {featuresData.map((service, index) => (
            <li key={index} className="flex">
              <div className="px-4 text-5xl font-extralight text-indigo-700">{service.number}</div>
              <div>
                <div className="text-xl font-bold text-indigo-800">{service.title}</div>
                <p className="max-w-xs py-2 text-sm text-indigo-900">{service.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Features;
