import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import studentsInfo from "./studentsInfo.json";
import StudentInfoPage from "./Components/StudentInfoPage";
import RankList from "./Components/RankList";
import ContestRank from "./Components/ContestRank";
import ContestWiseRank from "./Components/ContestWiseRank";
import Rank from "./Components/Rank";
import Home from "./Components/Home";
import Students from "./Components/Students";
import Testing from "./Components/Testing";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/Testing" element={<Testing />} />
        <Route path="/" element={<Home />} />
        <Route path="/Rank" element={<Rank/>}/>
        <Route path="/ContestRank" element={<ContestRank />} />
        <Route path="/RankList" element={<RankList />} />
        <Route path="/ContestWiseRank" element={<ContestWiseRank />} />
        <Route
          path="/Students"
          element={<Students/>}
        />
        <Route
          path="/students/:rollno"
          element={<StudentInfoPage students={studentsInfo} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
